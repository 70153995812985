.App {
  font-family: 'Teko', sans-serif;
  font-size: x-large;

}

nav {
  min-height: 20vh;
  max-height: 100vh;
  background: black;
  color: white;
  border-top: 2px solid red;
  border-bottom: 3px solid white;
}

.navitem {
  margin-left: auto;
}

h1, h2 {
  color: white;
}

h3 {
  color: rgb(161, 156, 156);
}

h4, h5, h6 {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-brand {
  display: flex;
}

.navbar-brand>img {
  display: flex;
  padding: 0px 0px;
  max-height: 100px;
}

.row {
  display: flex;
}

/*.column-split {
  display: flex;
  max-height: 490px;
}*/

.text-overlay { 
  position: absolute;
  top: 30px; 
  left: 30;
  color: white;
  width: 100%; 
  background: rgb(90, 90, 90);
}

.flex-container {
  display: flex;
  background: rgb(90, 90, 90);
}

.flex-container2 {
  display: flex;
  background: rgb(116, 113, 113);
  padding: 30px;
}

.flex-div {
  display: flex;
  background: rgb(116, 113, 113);
}

.flex-child {
  flex: 1;
  margin: 30px;
}  

.flex-child:first-child {
  margin-right: 0
} 

.flex-pic {
  max-width: 100%;
  max-height: 100%;
  min-width: 30%;
  
  /*opacity: 0.5;*/
}

.flex-pic2 {
  max-width: 50%;
  max-height: 50%;
  padding: 20px;
}

.flex-pic3 {
  float: left;
  max-height: 70%;
}

.site-footer {
  background: black;
  padding: 30px;
  display: flex;
  border-top: 2px solid red;
}

.div-footer {
  flex: 1;
  margin: 0px;
}

.footer-image {
  max-width: 85px;
  max-height: 85px;
  padding: 5px;
}

.banner-img {
  max-width: 100%;
}

.dark-bgd {
  background-color: black;
}

.centered {
  color: white;
  align-items: right; 
  top: 100px; 
  left: 400px; 
  width: 100%;
}

/*.services-banner {
  background-image: url("../src/img/gtr.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20vh;
  opacity: .5;
}

.media-banner {
  background-image: url("../src/img/albums.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20vh;
  opacity: .5;
}*/

.banner-title {
  padding: 50px;
  color: white;
  opacity: 1;
}

/*.align-right {
  margin-right: 0;
}*/

.align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pad {
  padding: 20px;
}

.error-mess {
  color: rgb(175, 22, 22);
  text-shadow: black;
}
